// Polyfill "picture"
// (siehe URL: http://scottjehl.github.io/picturefill/)
// (siehe URL: http://stackoverflow.com/a/21550322)
function loadScriptSync(src) {
    var s = document.createElement('script');
    s.src = src;
    s.type = "text/javascript";
    s.async = false;
    document.getElementsByTagName('head')[0].appendChild(s);
}

if (!window.HTMLPictureElement) {
    loadScriptSync('/build/base/js/polyfills/picturefill/picturefill.min.js');
}
