requirejs.config({
    paths: {
        jquery: '/build/base/js/lib/jquery.min',

        // COMPONENTS
        aos: '/build/hundertprozentgenuss/js/components/aos',
        slick: '/build/hundertprozentgenuss/js/components/slick',
        smoothScrolling: '/build/hundertprozentgenuss/js/components/smooth-scrolling',

        // LIBRARIES
        lib_aos: '/build/base/js/lib/aos',
        lib_slick: '/build/base/js/lib/slick'
    }
});
